import { debounce } from './debounce.js';
import { interactive } from './interactive.js';

// if ".is-uniform" class is presented patch element will work on mobile too

interactive().then(() => {
  const sections = Array.from(document.querySelectorAll('.Section--patch'));
  const activeClass = 'is-active';
  const uniformClass = 'is-uniform';
  const customPaddingClass = 'has-custom-padding';

  // do not execute in edit mode
  const url = window.location.href.toLowerCase();

  if (url.includes('/action/edit') || url.includes('/action/inedit')) {
    return;
  }

  if (document.body.classList.contains('.sfPageEditor')) {
    return;
  }

  if (!sections.length) {
    return;
  }

  function findCousin(elements, nextOrPrev, selector) {
    // Reference the last of the selected elements
    const reference = elements[elements.length - 1];

    // Set of all elements matching the selector
    const set = Array.from(document.querySelectorAll(selector));

    // Finds the position of the reference element in the set of all elements
    const pos = set.indexOf(reference);

    // If the element does not match the selector for cousin elements return null
    if (set.length === (pos - 1)) return null;

    // Return the next or previous cousin to the reference
    return set[pos + (nextOrPrev === 'next' ? 1 : (-1))];
  }

  function calculateOffsets() {
    const bodyWidth = document.body.offsetWidth;

    sections.forEach(patch => {
      const isUniformed = patch.classList.contains(uniformClass);
      const patchHalfHeight = `${Math.ceil(patch.offsetHeight / 2)}px`;

      if (isUniformed || bodyWidth > 1370) {
        const containers = patch.querySelectorAll('.container');
        const prevCousin = findCousin(containers, 'prev', '.Section .container, .TK-container');
        const nextCousin = findCousin(containers, 'next', '.Section .container, .TK-container');

        patch.classList.add(activeClass);
        patch.setAttribute('style', `margin-top: -${patchHalfHeight}; margin-bottom: -${patchHalfHeight}`);

        if (prevCousin) {
          prevCousin.classList.add(customPaddingClass);
          prevCousin.classList.toggle(uniformClass, isUniformed);
          prevCousin.style.paddingBottom = patchHalfHeight;
        }

        if (nextCousin) {
          nextCousin.classList.add(customPaddingClass);
          nextCousin.classList.toggle(uniformClass, isUniformed);
          nextCousin.style.paddingTop = patchHalfHeight;
        }
      }
    });
  }

  const calculateOffsetsDebounced = debounce(calculateOffsets);

  calculateOffsets();
  window.addEventListener('resize', calculateOffsetsDebounced, { passive: true });
});
