import { DataAttrParser } from '@progress-wad/data-attr-parser';

const defaultOptions = {
  slideSelector: 'blockquote',
  activeDotsClass: 'has-dots',
  dotsWrapperClassList: ['Carousel-dots', 'u-mt4'],
  dotsSelector: '.Carousel-dots, .Testimonials-dots'
};

class Testimonials {
  constructor(element, options = {}) {
    this.element = element;
    this.options = {
      ...defaultOptions,
      ...options,
      ...DataAttrParser.parse(element.dataset, ['testimonials'])
    };
    this.hasDots = this.element.classList.contains(this.options.activeDotsClass);
    this.slides = Array.from(this.element.querySelectorAll(this.options.slideSelector));
    this.generateDots();
    this.initiateTabsPlugin();
  }

  static generateDotElement(index) {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', 'javascript:void(0)'); // eslint-disable-line no-script-url
    anchor.setAttribute('aria-label', `go to slide ${index + 1}`);
    if (index === 0) anchor.classList.add('is-active');
    return anchor;
  }

  generateDots() {
    if (this.hasDots) {
      // The capability to have static dots is needed because:
      // It's better to server-side render the dots for performance reasons (to avoid CLS)
      // Note! It's terrible that this is a global selector, and it can but it's needed for backwards compatibility
      this.dotsWrapper = document.querySelector(this.options.dotsSelector);
      return;
    }

    this.element.classList.add(this.activeDotsClass);
    this.hasDots = true;

    this.dotsWrapper = document.createElement('div');
    this.dotsWrapper.classList.add(...this.options.dotsWrapperClassList);

    this.dots = this.slides.map((slide, index) => {
      const dot = Testimonials.generateDotElement(index);
      this.dotsWrapper.append(dot);
      return dot;
    });

    this.element.after(this.dotsWrapper);
  }

  initiateTabsPlugin() {
    const dataAttr = DataAttrParser.parse(this.dotsWrapper.dataset, ['tlrktabs']);
    const carryoverAttributes = dataAttr.tlrktabs || {};

    $(this.dotsWrapper).tlrkTabs({
      wrapperSelector: $(this.element),
      automated: true,
      setAnchors: false,
      scrollToTabs: false,
      animateHeight: true,
      ...carryoverAttributes
    });
  }
}

(() => {
  document.querySelectorAll('.Testimonials').forEach(element => new Testimonials(element));
})();
