const defaultConfig = {
  optionTemplateSelector: 'template',
  titleSelector: '.js-title',
  subtitleSelector: '.js-subtitle',
  descriptionSelector: '.js-description',
  optionsWrapClassName: '.Purchase-mobileHead-options',
  mainBtnSelector: '.Purchase-mobileHead-main',
  rowsSelector: '.Purchase-head, .Purchase-body div',
  colsSelector: '.Purchase-cell, dd',
  openClass: 'is-open',
  activeColClass: 'is-active',
  activeOptionClass: 'is-active',
  activeColumn: 0
};

const pluginName = 'MobileHeader';

class MobileHeader {
  constructor(element, config) {
    this.config = {
      ...defaultConfig,
      ...config
    };

    this.element = element;
    this.options = [];
    this.isOpen = false;
    this.template = element.querySelector(this.config.optionTemplateSelector);
    this.optionsWrap = element.querySelector(this.config.optionsWrapClassName);
    this.main = this.element.querySelector(this.config.mainBtnSelector);
    this.title = this.main.querySelector(this.config.titleSelector);
    this.subtitle = this.main.querySelector(this.config.subtitleSelector);
    this.description = this.main.querySelector(this.config.descriptionSelector);

    // The below generates 2d array of rows and columns
    this.rows = Array.from(document.querySelectorAll(this.config.rowsSelector))
      .map(row => row.querySelectorAll(this.config.colsSelector));

    this.buildOptions();

    this.main.addEventListener('click', this.toggle.bind(this));
    this.setActiveColumn(this.config.activeColumn);
    this.optionClickHandler(this.options[this.config.activeColumn], this.config.activeColumn);

    if (this.config.observe) {
      document.querySelectorAll(this.config.observe).forEach(el => this.observe(el));
    }
  }

  toggle() {
    if (!this.isOpen) {
      this.open();
      return;
    }
    this.close();
  }

  open() {
    this.calculateDropdownMaxHeight();
    this.element.classList.add(this.config.openClass);
    this.isOpen = true;
  }

  close() {
    this.element.classList.remove(this.config.openClass);
    this.isOpen = false;
  }

  calculateDropdownMaxHeight() {
    this.optionsWrap.style.maxHeight = `${this.optionsWrap.scrollHeight}px`;
  }

  optionClickHandler(option, index, shouldDispatchEvent = true) {
    const currentActiveOption = this.options[this.activeColumn];
    this.setActiveColumn(index);

    const optionTitle = option.querySelector(this.config.titleSelector);
    const optionSubtitle = option.querySelector(this.config.subtitleSelector);
    const optionDescription = option.querySelector(this.config.descriptionSelector);

    if (optionTitle && this.title) this.title.innerHTML = optionTitle.innerHTML;
    if (optionSubtitle && this.subtitle) this.subtitle.innerHTML = optionSubtitle.innerHTML;
    if (optionDescription && this.description) this.description.innerHTML = optionDescription.innerHTML;

    if (currentActiveOption) currentActiveOption.classList.remove(this.config.activeOptionClass);
    option.classList.add(this.config.activeOptionClass);

    if (shouldDispatchEvent) {
      const changeEvent = new Event('change');
      changeEvent.option = option;
      changeEvent.index = index;
      this.element.dispatchEvent(changeEvent);
    }

    this.close();
  }

  syntheticChangeHandler(event) {
    const option = this.options[event.index];
    if (!option) return;
    this.optionClickHandler(option, event.index, false);
  }

  observe(mobileHeadElement) {
    if (!mobileHeadElement || mobileHeadElement === this.element) return;
    mobileHeadElement.addEventListener('change', this.syntheticChangeHandler.bind(this));
  }

  setActiveColumn(newActiveColumn) {
    this.activeColumn = newActiveColumn;

    this.rows.forEach(row => {
      row.forEach((column, index) => {
        if (newActiveColumn === index) {
          column.classList.add(this.config.activeColClass);
          return;
        }
        column.classList.remove(this.config.activeColClass);
      });
    });
  }

  buildOptions() {
    const optionsDataset = document.querySelectorAll(this.template.dataset.foreach);

    optionsDataset.forEach((optionNode, index) => {
      const optionContent = this.template.content.firstElementChild.cloneNode(true);
      const optionPlaceholders = optionContent.querySelectorAll('[data-copy-from]');

      optionPlaceholders.forEach(placeholder => {
        const refElement = optionNode.querySelector(placeholder.dataset.copyFrom);
        if (refElement) placeholder.innerHTML = refElement.innerHTML;
      });

      this.optionsWrap.appendChild(optionContent);
      this.options.push(optionContent);
      optionContent.addEventListener('click', this.optionClickHandler.bind(this, optionContent, index));
    });
  }
}

$.fn[pluginName] = function(options) {
  return this.each(function() {
    if (!$.data(this, `plugin_${pluginName}`)) {
      new MobileHeader(this, options);
    }
  });
};
