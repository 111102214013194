import { interactive } from './interactive.js';

/**
 * # Loader
 *
 * ## On Dom Ready
 *
 * If `.js--Loader` class is present,
 * loader will reveal itself on `DOMContentLoaded` event
 *
 * Markup:
 * <div class="Loader js--Loader">
 *   <div class="Loader-Content">
 *     <iframe width="100%" scrolling="no" id="js--iframe"></iframe>
 *   </div>
 * </div>
 *
 *
 * ## On Custom Element Load Event
 *
 * If `.js--Loader` class is present,
 * and `data-wait-selector` attr is present,
 * loader will reveal itself when all elements from the data attr set are loaded
 *
 * Markup:
 * <div class="Loader js--Loader" data-wait-selector=".js--iframe">
 *   <div class="Loader-Content">
 *     <iframe width="100%" scrolling="no" id="js--iframe" class="js--iframe"></iframe>
 *   </div>
 * </div>
 *
 *
 * ## On document Custom Event
 *
 * If `.js--Loader` class is present,
 * and `data-wait-event` attr is present,
 * loader will reveal when the custom event is dispatched
 *
 * Markup:
 * <div class="Loader js--Loader" data-wait-event="some-event-name">
 *   <div class="Loader-Content">
 *     <iframe width="100%" scrolling="no" id="js--iframe" class="js--iframe"></iframe>
 *   </div>
 * </div>
 */


// fancy dom ready
interactive().then(() => {
  const loaders = document.querySelectorAll('.js--Loader');
  const loadedCssClass = 'Loader--Ready';

  /**
   * Add loaded css class when ready
   * @param {HTMLElement} loader - the loader element
   */
  function onReady(loader) {
    const delay = Number(loader.dataset.waitDelay);
    const reveal = () => loader.classList.add(loadedCssClass);

    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        delay ? setTimeout(reveal, delay) : reveal();
      });
    });
  }

  loaders.forEach(loader => {
    const { waitEvent } = loader.dataset;
    const { waitSelector } = loader.dataset;

    if (waitEvent) {
      document.addEventListener(waitEvent, () => onReady(loader));
    }

    if (waitSelector == null) {
      // if no selector is given to wait, just reveal the loader
      onReady(loader);
    }
    else {
      // may be images, iframes, videos, everything with `load` event
      const elementsToWait = [...document.querySelectorAll(waitSelector)];

      const loadPromises = elementsToWait.map(element => new Promise((resolve, reject) => {
        element.addEventListener('load', resolve);
        element.addEventListener('error', reject);
      }));

      // when all elements are loaded, reveal the loader
      Promise
        .allSettled(loadPromises)
        .finally(() => onReady(loader));
    }
  });
});
