(function liteSliderClosure() {
  const { body } = document;
  const html = document.documentElement;
  const pageWrapper = document.querySelector('.PageWrapper');
  // var TOUCH_DEVICE = !!('ontouchstart' in window || navigator.maxTouchPoints);

  /* "wpf", "aspnet-core-ui", etc... */
  const productName = window.location.pathname
    .replace('products/', '')
    .replace('/overview.aspx', '')
    .replace('/', '')
    .replace('.aspx', '');

  /* find element in container */
  function find(parent, selector) {
    return parent.querySelector(selector);
  }

  /* find all elements in container by selector */
  function findAll(parent, selector) {
    return Array.prototype.slice.call(parent.querySelectorAll(selector));
  }

  /* gtm tracking function */
  function track(eventLabel) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'virtualEvent',
      eventCategory: 'product-features',
      eventAction: 'text',
      eventLabel
    });
  }

  /* LiteSlider common */
  /* ================= */
  const ESCAPE = 27;
  const ARROW_LEFT = 37;
  const ARROW_RIGHT = 39;

  /* All css classes */
  const FIXED_CLASS = 'is-fixed';
  const VISIBLE_CLASS = 'is-visible';
  const Z_INDEX_MAX_CLASS = 'u-zi-max';
  const OVERFLOW_HIDDEN_CLASS = 'u-oh';
  const POSITION_RELATIVE_CLASS = 'u-pr'; // To prevent scrolling on body, safari requires relative positon and hidden overflow

  /* LiteSlider contructor */
  /* ===================== */

  function LiteSlider(element, options) {
    this.element = element;

    this.grid = options.grid;
    this.section = options.section;
    // TOUCH_DEVICE && (this.hammer = new Hammer(this.section));

    this.links = options.links;
    this.items = options.items;
    this.breadcrumb = options.breadcrumb;

    this.controls = {
      prev: options.prev,
      next: options.next,
      close: options.close
    };

    this._staged = false;
    this._activeItem = null;

    this.hashes = this.items.map(item => item.dataset.id.toLowerCase());

    this.onLinkClick = this.onLinkClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onHashChange = this.onHashChange.bind(this);

    this.onKeyboard = this.onKeyboard.bind(this);
    this.enableKeyboard = this.enableKeyboard.bind(this);
    this.disableKeyboard = this.disableKeyboard.bind(this);

    // this.enableSwipe = this.enableSwipe.bind(this);
    // this.disableSwipe = this.disableSwipe.bind(this);
    // this.onSwipeLeft = this.onSwipeLeft.bind(this);
    // this.onSwipeRight = this.onSwipeRight.bind(this);

    this.init();
  }


  LiteSlider.prototype.getActiveItem = function getActiveItem() {
    return this._activeItem;
  };

  LiteSlider.prototype.getItemByDataId = function getItemByDataId(dataId) {
    return this.items.filter(item => item.dataset.id === dataId)[0];

    // It is not valid selector if data-id starts with Number
    // return this.element.querySelector('[data-id=' + dataId + ']');
  };

  LiteSlider.prototype.getInfo = function getInfo() {
    const min = 0;
    const max = this.items.length - 1;

    const activeItem = this.getActiveItem();
    const activeIndex = this.items.indexOf(activeItem);

    const nextIndex = activeIndex === max ? min : activeIndex + 1;
    const prevIndex = activeIndex === min ? max : activeIndex - 1;

    return {
      active: {
        index: activeIndex,
        dataId: activeItem.dataset.id
      },
      next: {
        index: nextIndex,
        dataId: this.items[nextIndex].dataset.id
      },
      prev: {
        index: prevIndex,
        dataId: this.items[prevIndex].dataset.id
      }
    };
  };

  LiteSlider.prototype.showStage = function showStage() {
    if (this._staged) {
      return;
    }
    html.classList.add(OVERFLOW_HIDDEN_CLASS, POSITION_RELATIVE_CLASS); // apple
    body.classList.add(OVERFLOW_HIDDEN_CLASS, POSITION_RELATIVE_CLASS); // apple
    pageWrapper.classList.add(Z_INDEX_MAX_CLASS);
    this.section.classList.add(FIXED_CLASS);
    this.grid.classList.remove(VISIBLE_CLASS);
    this.element.classList.add(VISIBLE_CLASS);
    this.enableKeyboard();
    // TOUCH_DEVICE && this.enableSwipe();
    this._staged = !this._staged;
  };

  LiteSlider.prototype.hideStage = function hideStage() {
    if (!this._staged) {
      return;
    }
    html.classList.remove(OVERFLOW_HIDDEN_CLASS, POSITION_RELATIVE_CLASS);
    body.classList.remove(OVERFLOW_HIDDEN_CLASS, POSITION_RELATIVE_CLASS);
    pageWrapper.classList.remove(Z_INDEX_MAX_CLASS);
    this.section.classList.remove(FIXED_CLASS);
    this.grid.classList.add(VISIBLE_CLASS);
    this.element.classList.remove(VISIBLE_CLASS);
    this.grid.scrollIntoView();
    this.disableKeyboard();
    // TOUCH_DEVICE && this.disableSwipe();
    this._staged = !this._staged;
  };

  LiteSlider.prototype.showItem = function showItem(dataId) {
    const item = this.getItemByDataId(dataId);
    this._activeItem = item;
    this.showStage();
    this.setBreadcrumb(item);
    this.updateControlLinks();
    this.setHash(dataId);
    this._activeItem.classList.add(VISIBLE_CLASS);
    track(`${productName}/${dataId}`);
  };

  LiteSlider.prototype.hideItem = function hideItem(dataId, options) {
    const item = this.getItemByDataId(dataId);
    const defaults = { hideStage: true, cleanHash: true };
    const settings = Object.assign({}, defaults, options || {});
    item.classList.remove(VISIBLE_CLASS);
    if (settings.hideStage) this.hideStage();
    if (settings.cleanHash) this.cleanHash();
    this._activeItem = null;
  };

  LiteSlider.prototype.showNextItem = function showNextItem() {
    const info = this.getInfo();
    this.hideItem(info.active.dataId, { hideStage: false, cleanHash: false });
    this.showItem(info.next.dataId);
  };

  LiteSlider.prototype.showPrevItem = function showPrevItem() {
    const info = this.getInfo();
    this.hideItem(info.active.dataId, { hideStage: false, cleanHash: false });
    this.showItem(info.prev.dataId);
  };

  LiteSlider.prototype.hideActiveItem = function hideActiveItem(options) {
    const info = this.getInfo();
    this.hideItem(info.active.dataId, options);
  };

  /* Update breadcrumb */
  /* ======================== */

  LiteSlider.prototype.setBreadcrumb = function setBreadcrumb(item) {
    this.breadcrumb.innerHTML = find(item, '.js-feature-headline').textContent;
  };

  /* Update all control links */
  /* ======================== */

  LiteSlider.prototype.updateControlLinks = function updateControlLinks() {
    const info = this.getInfo();

    const nextLinkHref = `#${info.next.dataId}`;
    const nextLinkText = `Next: ${find(this.items[info.next.index], '.js-feature-headline').textContent}`;

    const prevLinkHref = `#${info.prev.dataId}`;
    const prevLinkText = `Previous: ${find(this.items[info.prev.index], '.js-feature-headline').textContent}`;

    this.controls.next.forEach(link => {
      link.href = nextLinkHref; // eslint-disable-line no-param-reassign
      link.innerHTML = nextLinkText; // eslint-disable-line no-param-reassign
    });

    this.controls.prev.forEach(link => {
      link.href = prevLinkHref; // eslint-disable-line no-param-reassign
      link.innerHTML = prevLinkText; // eslint-disable-line no-param-reassign
    });
  };

  /* Click controls events */
  /* ===================== */

  LiteSlider.prototype.onLinkClick = function onLinkClick(event) {
    const { target } = event;
    const link = target.tagName === 'A' ? target : target.closest('a');
    const dataId = link.hash.replace('#', '');
    this.showItem(dataId);
    event.preventDefault();
  };

  LiteSlider.prototype.onCloseClick = function onCloseClick(event) {
    this.hideActiveItem();
    event.preventDefault();
    track(`${productName}/all`);
  };

  LiteSlider.prototype.onNextClick = function onNextClick(event) {
    this.showNextItem();
    event.preventDefault();
  };

  LiteSlider.prototype.onPrevClick = function onPrevClick(event) {
    this.showPrevItem();
    event.preventDefault();
  };

  /* Touch/Swipe Events */
  /* ================== */

  // LiteSlider.prototype.onSwipeLeft = function() {
  //   this.showNextItem();
  //   this.throttleSwipe();
  // };

  // LiteSlider.prototype.onSwipeRight = function() {
  //   this.showPrevItem();
  //   this.throttleSwipe();
  // };

  // LiteSlider.prototype.enableSwipe = function() {
  //   this.hammer.on('swipeleft', this.onSwipeLeft);
  //   this.hammer.on('swiperight', this.onSwipeRight);
  //   this._activeSwipe = true;
  // };

  // LiteSlider.prototype.disableSwipe = function() {
  //   this.hammer.off('swipeleft', this.onSwipeLeft);
  //   this.hammer.off('swiperight', this.onSwipeRight);
  //   this._activeSwipe = false;
  // };

  // LiteSlider.prototype.throttleSwipe = function() {
  //   if (this._activeSwipe) {
  //     this.disableSwipe();
  //     setTimeout(this.enableSwipe, 100);
  //   }
  // };

  /* Keyboard Events */
  /* ================== */

  LiteSlider.prototype.onKeyboard = function onKeyboard(event) {
    const key = event.keyCode;

    if (event.altKey || event.ctrlKey) {
      return;
    }

    if (key === ESCAPE) {
      this.hideActiveItem();
      track(`${productName}/all`);
    }

    if (key === ARROW_LEFT) {
      this.showPrevItem();
    }

    if (key === ARROW_RIGHT) {
      this.showNextItem();
    }

    this.throttleKeyboard();
  };

  LiteSlider.prototype.enableKeyboard = function enableKeyboard() {
    document.addEventListener('keydown', this.onKeyboard, false);
    this._activeKeyboard = true;
  };

  LiteSlider.prototype.disableKeyboard = function disableKeyboard() {
    document.removeEventListener('keydown', this.onKeyboard, false);
    this._activeKeyboard = false;
  };

  LiteSlider.prototype.throttleKeyboard = function throttleKeyboard() {
    if (this._activeKeyboard) {
      this.disableKeyboard();
      setTimeout(this.enableKeyboard, 100);
    }
  };

  /* Hash Events */
  /* ================== */

  LiteSlider.prototype.isValidHash = function isValidHash(hash) {
    return this.hashes.indexOf(hash) !== -1;
  };

  LiteSlider.prototype.setHash = function setHash(hash) {
    window.location.hash = hash;
  };

  LiteSlider.prototype.cleanHash = function cleanHash() {
    try {
      window.history.pushState('', document.title, window.location.pathname);
    }
    catch (err) {
      window.location.hash = '';
    }
  };

  LiteSlider.prototype.onHashChange = function onHashChange(event) {
    const newHash = event.newURL.split('#')[1];
    const activeItem = this.getActiveItem();
    const isValidHash = this.isValidHash(newHash);

    if (!isValidHash) {
      if (activeItem) this.hideActiveItem();
      return;
    }

    if (!activeItem) {
      this.showItem(newHash);
    }

    // if (newHash === activeItem.dataset.id) {

    // }

    // setTimeout(function() {
    //   this.hideActiveItem({ hideStage: false, cleanHash: false });
    //   this.showItem(newHash);
    // }.bind(this), 100);
  };

  /* Add event listeners with init method */
  /* ========================================== */

  LiteSlider.prototype.init = function initLiteSlider() {
    this.links.forEach(function forEach(link) {
      link.addEventListener('click', this.onLinkClick, false);
    }, this);

    this.controls.close.forEach(function forEach(link) {
      link.addEventListener('click', this.onCloseClick, false);
    }, this);

    this.controls.next.forEach(function forEach(link) {
      link.addEventListener('click', this.onNextClick, false);
    }, this);

    this.controls.prev.forEach(function forEach(link) {
      link.addEventListener('click', this.onPrevClick, false);
    }, this);

    window.addEventListener('hashchange', this.onHashChange, false);
  };

  /* Init slider on web page */
  /* ======================= */
  function init() {
    const featuresWrapper = document.querySelector('#Features');
    if (!featuresWrapper) {
      return;
    }

    const section = featuresWrapper.closest('.Section');
    if (!section) {
      return;
    }

    const sliderWrapper = find(featuresWrapper, '.Features-Slider-Wrapper');
    if (!sliderWrapper) {
      return;
    }

    const liteSlider = new LiteSlider(sliderWrapper, {
      section,
      grid: find(featuresWrapper, '.Features-grid'),
      links: findAll(featuresWrapper, '.js-open-feature'),
      items: findAll(sliderWrapper, '.Features-Slider-List-Item'),
      breadcrumb: find(sliderWrapper, '.js-features-breadcrumb-item'),
      next: findAll(sliderWrapper, 'a[data-action="next"]'),
      prev: findAll(sliderWrapper, 'a[data-action="prev"]'),
      close: findAll(sliderWrapper, 'a[data-action="all"]')
    });

    /* Mark parent section as Section--Features -> display: flex */
    section.classList.add('Section--Features');

    /* if we load the page with hash - open the feature */
    const initialHash = window.location.hash.replace('#', '').toLowerCase();

    if (liteSlider.isValidHash(initialHash)) {
      liteSlider.showItem(initialHash);
    }
  }

  init();
})();
