/* eslint-disable */
(function() {
  if (typeof ValidatorUpdateDisplay !== 'undefined') {
    if ($.isFunction(ValidatorUpdateDisplay)) {
      var FailInputsContainerClass = 'FailedInput';

      const NewValidatorUpdate = function NewValidatorUpdate(val) {
        OldValidatorUpdateDisplay(val);
        if (val.style.display === 'inline' || val.style.visibility === 'visible') {
          val.style.display = 'block';
          let parent = $(val).parent();

          parent = (parent.hasClass('has-reveal')) ? parent.parent() : parent;
          parent.addClass(FailInputsContainerClass);
        }
      };

      window.OldValidatorUpdateDisplay = ValidatorUpdateDisplay;
      window.ValidatorUpdateDisplay = NewValidatorUpdate;
    }

    if ($.isFunction(Page_ClientValidate)) {
      const NewPage_ClientValidate = function NewPage_ClientValidate(val) {
        $(`.${FailInputsContainerClass}`).removeClass(FailInputsContainerClass);
        return OldPage_ClientValidate(val);
      };

      window.OldPage_ClientValidate = Page_ClientValidate;
      window.Page_ClientValidate = NewPage_ClientValidate;

      ValidatorOnChange = function(event) {
        event = event || window.event;
        Page_InvalidControlToBeFocused = null;

        let targetedControl;

        if ((typeof (event.srcElement) !== 'undefined') && (event.srcElement != null)) {
          targetedControl = event.srcElement;
        }
        else {
          targetedControl = event.target;
        }

        $(targetedControl).closest(`.${FailInputsContainerClass}`).removeClass(FailInputsContainerClass);

        let vals;

        if (typeof (targetedControl.Validators) !== 'undefined') {
          vals = targetedControl.Validators;
        }
        else if (targetedControl.tagName.toLowerCase() == 'label') {
          targetedControl = document.getElementById(targetedControl.htmlFor);
          vals = targetedControl.Validators;
        }

        if (vals) {
          for (let i = 0; i < vals.length; i++) {
            ValidatorValidate(vals[i], null, event);
          }
        }
        ValidatorUpdateIsValid();
      };
    }
  }
})();
/* eslint-enable */
