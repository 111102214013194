/**
 * This plugin is considered legacy code
 * Do not use for new pages, use sharer instead
 */
import $ from 'jquery';

(function tlrkShareClosure() {
  const pluginName = 'tlrkShare';

  const defaults = {
    profile: 'counter-side',
    action: 'replace', // todo
    profiles: {
      'counter-side': {
        'facebook-like': false,
        'facebook-share': true,
        twitter: true,
        linkedin: true,
        mail: true,
        counter: 'side' // [side|top|none]
      },
      'counter-top': {
        'facebook-like': false,
        'facebook-share': true,
        twitter: true,
        linkedin: true,
        mail: false,
        counter: 'top'
      },
      basic: {
        'facebook-like': false,
        'facebook-share': true,
        twitter: true,
        linkedin: true,
        mail: true,
        counter: 'none'
      }
    }

  };

  function Plugin(element, options) {
    this.element = element;
    this.$element = $(element);
    this.options = $.extend({}, defaults, options);
    this._name = pluginName;
    this.oldContent = '';
    this.$window = $(window);

    this.buttonTypes = {
      side: {
        'facebook-ext': 'button_count',
        facebook: 'bubble',
        twitter: 'horizontal',
        linkedin: 'right'
      },
      top: {
        'facebook-ext': 'box_count',
        facebook: 'box_count',
        twitter: 'vertical',
        linkedin: 'top'
      },
      none: {
        'facebook-ext': 'button',
        facebook: '',
        twitter: 'none',
        linkedin: ''
      }

    };

    this.oldContent = this.$element.html();
    this.$element.html('');

    this.runProfile();
  }

  Plugin.prototype = {
    runProfile() {
      this.loadSharrre();
      if (this.getProfileSetting('facebook-share')) {
        this.loadExtFacebook();
      }
      if (this.getProfileSetting('mail')) {
        this.initMailTo();
      }
    },

    loadSharrre() {
      const that = this;
      if (typeof $.fn.sharrre !== 'function') {
        // will be chaged when we have it on cloudfront?
        $.getScript('//cdn.jsdelivr.net/sharrre/1.3.5/jquery.sharrre.min.js', () => {
          that.initSharrre();
        });
      }
      else {
        that.initSharrre();
      }
    },

    loadExtFacebook() {
      $('<div id="fb-root" class="u-dn"></div>').appendTo(this.$element);

      (function loadFbSdk(d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];

        if (d.getElementById(id)) {
          return;
        }

        const js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&appId=0&version=v2.0';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');

      this.initExtFacebook();
    },

    initSharrre() {
      this.$element.sharrre({
        share: {
          facebook: this.getProfileSetting('facebook-like'),
          twitter: this.getProfileSetting('twitter'),
          linkedin: this.getProfileSetting('linkedin')
        },
        buttons: {
          facebook: { layout: this.getProfileButtonType('facebook') },
          twitter: { count: this.getProfileButtonType('twitter') },
          linkedin: { counter: this.getProfileButtonType('linkedin') }
        },
        enableHover: false,
        enableCounter: false,
        enableTracking: (this.getProfileSetting('counter') !== 'none')
      });
    },

    initExtFacebook() {
      this.$element.prepend(`<div class="fb-share-button" data-href="${document.URL}" data-type="${this.getProfileButtonType('facebook-ext')}"></div>`);
    },

    initMailTo() {
      this.$element.append(`<a href="mailto:?subject=${$('head title').text()}&amp;body=${document.URL}" class="mailto js-mailto"></a>`);

      $('.js-mailto').on('click', function onMailtoClick(e) {
        e.preventDefault();
        document.location.href = $(this).attr('href');
      });
    },

    getProfileSetting(setting) {
      return this.options.profiles[this.options.profile][setting];
    },

    getProfileButtonType(provider) {
      return this.buttonTypes[this.options.profiles[this.options.profile].counter][provider];
    }

  };

  $.fn[pluginName] = function tlrkSharePlugin(options) {
    return this.each(function eachPlugin() {
      if (!$.data(this, `plugin_${pluginName}`)) {
        $.data(this, `plugin_${pluginName}`, new Plugin(this, options));
      }
    });
  };
})();
