(function ieHashChange() {
  /* IE */

  if (!window.HashChangeEvent) {
    let lastURL = document.URL;
    window.addEventListener('hashchange', event => {
      Object.defineProperty(event, 'oldURL', { enumerable: true, configurable: true, value: lastURL });
      Object.defineProperty(event, 'newURL', { enumerable: true, configurable: true, value: document.URL });
      lastURL = document.URL;
    });
  }
})();
