import $ from 'jquery';

$(() => {
  if (!$('.js-reveal').length) {
    return;
  }

  const $videos = $('video');
  const $elements = $('.js-reveal').addClass('js-reveal-hidden js-reveal-reset');
  const $header = $('.Section-banner');
  const $sticky = $('.Nav.is-fixed');
  const viewportGutterTop = 100; // make sure at least this amount of pixels is visible | set 0 not to use this
  const viewportGutterBottom = 100; // make sure at least this amount of pixels is visible | set 0 not to use this
  const timeBetweenLoop = 2000; // in miliseconds
  let elScrolling = false;

  function calcElPosition(obj) {
    const elPosition = obj.offset().top;
    return [elPosition, elPosition + obj.height()];
  }

  function mScrollHandler() {
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    if (viewportTop > $header.height()) {
      $sticky.fadeIn();
    }
    else {
      $sticky.fadeOut();
    }

    $.each($elements, (i, obj) => {
      const $obj = $(obj);
      const elData = calcElPosition($obj);
      const elTop = elData[0];
      const elBottom = elData[1];
      $(obj).removeClass('js-reveal-reset');

      if ((elBottom - viewportGutterBottom) > viewportTop && elTop < (viewportBottom - viewportGutterTop)) {
        // into view
        window.setTimeout(() => {
          $obj.removeClass('js-reveal-hidden');
          if ($obj.is('video')) {
            obj.play();
          }
        }, 1);
      }
      else if ($obj.is('video')) {
        obj.pause();
      }
    });
  }

  function loopVideo(vid) {
    window.setTimeout(() => {
      vid.currentTime = 0; // eslint-disable-line
      vid.play(); // eslint-disable-line
    }, timeBetweenLoop);
  }

  // attach onEnded event of all videos to loop
  $videos.on('ended', function ended() {
    loopVideo(this);
  });

  // trigger onScroll event
  $(window).on('scroll', () => {
    elScrolling = true;
  });

  window.setInterval(() => {
    if (elScrolling) {
      elScrolling = false;
      mScrollHandler();
    }
  }, 250);

  mScrollHandler();
});
