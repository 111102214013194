/*jshint asi:true */

/**
 * jQuery.support.cssProperty
 * To verify that a CSS property is supported (or any of its browser-specific implementations)
 *
 * @Author: Axel Jack Fuchs (Cologne, Germany)
 * @Date: 08-29-2010 18:43
 *
 */
;(function( $, window, document, undefined ){
  "use strict";
  var TRANSITIONS = $.support.cssProperty("transition");

  var TlrkSlide = function( elem, options ){
    var $wrap, $strip, $slides, $elem,
    itemCSS,
    that = this;

    this.elem = elem;
    this.$elem = $elem = $(elem);
    this.options = options;
    this.$slides = $slides = $elem.find(this.options.slides);
    this.current = 0;
    this.max = $slides.length + (options.seamless ? 1 : 0 );
    this.activeDotClass = options.activeDotClass;

    this.itemWidth = (100 / this.max);

    if (options.nav) {
      this.nav = true;
      this.$nav = options.generateNav ? $(this._generateNav( options.seamless ? this.max-1 : this.max )).appendTo($elem) : $(options.cssNav);
      this.$nav
        .find("a:first").addClass(this.activeDotClass).end()
        .delegate("a", "click", function(e) {
          e.preventDefault();
          that.go($(this).data("slide"));
        })
    }

    if (options.prev) {
      $(options.prev).bind("click", function() {
        that.prev();
        return false;
      })
    }

    if (options.next) {
      $(options.next).bind("click", function() {
        that.next();
        return false;
      })
    }

    $strip = $("<div class='" + options.cssStrip + "'/>")
    $wrap = $("<div class='" + options.cssWrap + "'/>")

    $strip.css({
      width: 100 * this.max + "%",
      position: "relative"
    })

    itemCSS = {
      margin: 0,
      "display": "inline-block"
    }

    itemCSS.width = 100 / this.max + "%";

    $slides.css(itemCSS);

    $slides.appendTo($strip);

    // add additional slide, cloning the first one
    if (options.seamless) {
      $slides.eq(0).clone().appendTo($strip);
    }

    $strip.appendTo($wrap);
    $wrap.prependTo($elem);

    this.$strip = $strip;
    this.$wrap = $wrap;

    this._setSpeed();

    if (options.play) {
      this.start()
      if (options.stopOnHover) {
        this.$elem
        .on("mouseenter", $.proxy(this.stop, this))
        .on("mouseleave", function() {that.start()});
      }
    }

    // swipe support?
    if (typeof window.ontouchstart != "undefined") {
      $wrap[0].addEventListener("touchstart", $.proxy(this._touchstart, this));
      $wrap[0].addEventListener("touchend", $.proxy(this._touchend, this));
      $wrap[0].addEventListener("touchcancel", $.proxy(this._touchend, this));
      $wrap[0].addEventListener("touchmove", $.proxy(this._touchmove, this));
    }

  }

  TlrkSlide.prototype = {
    _translateX: function(x) {
      var style = this.$strip[0].style;
      style.MozTransform = style.webkitTransform = style.transform = style.msTransform = style.OTransform = "translateX(" + -(x) + "%)";
    },
    _setSpeed: function(speed) {
      var style = this.$strip[0].style;
      if (speed === 0) {
        speed = 0;
        style.webkitTransitionDuration = style.MozTransitionDuration = style.msTransitionDuration = style.OTransitionDuration = style.transitionDuration = "0ms";
        return
      }
      speed = speed || this.options.speed;
      style.webkitTransitionDuration = style.MozTransitionDuration = style.msTransitionDuration = style.OTransitionDuration = style.transitionDuration = speed + "ms";
    },
    _resetPosition: function(frame) {
      if (typeof(frame) === "undefined") {
        frame = this.current;
      }
      if (TRANSITIONS) {
        this._translateX(frame * this.itemWidth);
      } else {
        this.$strip.css("left", "-" + (100 * frame) + "%" )
      }
    },
    _setActiveNav: function(idx) {
      if (typeof(idx) === "undefined") {
        idx = this.current;
      }
      this.$nav
        .find("." + this.activeDotClass).removeClass(this.activeDotClass).end()
        .find("[data-slide='" + idx + "']").addClass(this.activeDotClass);
    },
    go: function(idx) {
      var that = this,
          options = this.options;

      if (idx < -1 || idx > this.max-1 || idx === this.current) {
        return
      }

      // preventing fast switch
      if (options.seamless && this.isAnimated) {
        return
      }

      this.target = idx;
      this._animationStart();

      if (TRANSITIONS) {
        // we'll need a minimal delay so the possible speed resetting in _animationStart doesn't mess this transition speed
        window.setTimeout(function() {
          that.$strip.one("transitionend otransitionend webkitTransitionEnd", $.proxy(that._animationEnd, that));
          that._translateX(that.target * (100 / that.max));
        }, 4);
      } else {
        this.$strip.animate({ left: "-" + (100 * this.target) + "%" }, options.speed, $.proxy(this._animationEnd, this));
      }

      this.current = this.target;

      if (this.nav) {
        this._setActiveNav( options.seamless && this.current === this.max-1 ? 0 : this.current );
      }

    },
    next: function() {
      var n = this.current + 1;
      if (n > this.max-1 && this.options.loop) {
        n = 0;
      }
      this.go(Math.min(n, this.max-1));
    },
    prev: function() {
      var n = this.current-1;
      if (n < 0 && this.options.loop) {
        n = this.max - 1;
      }
      this.go(Math.max(n, 0));
    },
    start: function(startMeNow) {
      this.playing = true;
      if (startMeNow) {
        this.next();
      }
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout($.proxy(this.start, this, true), this.options.playTimeout);
    },
    stop: function() {
      this.playing = false;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
    },
    _touchstart: function(e) {
      this.scrolling = undefined;
      this._setSpeed(0);
      this.deltaX = 0;
      this.startX = e.touches[0].pageX;
      this.startY = e.touches[0].pageY;
      this.stripeWidth = this.$strip.width();
      e.stopPropagation();
    },
    _touchend: function() {
      var MIN_DELTA = (this.stripeWidth / 1000)

      this._setSpeed();

      if (this.scrolling) {
        return
      }

      if (Math.abs(this.deltaX) > MIN_DELTA) {
        if (this.deltaX < 0 && this.current > 0) {
          this.prev();
        } else if (this.deltaX > 0 && this.current < this.max -1) {
          this.next();
        } else {
          this._resetPosition();
        }
      } else {
        this._resetPosition();
      }

      this.deltaX = null;
      this.startX = null;
      this.startY = null;
    },
    _touchmove: function(e) {
      var base = this.current * this.itemWidth;

      // if pinch?
      if (e.touches.length > 1 || e.scale && e.scale !== 1) {
        return
      }

      this.deltaX = this.startX - e.touches[0].pageX;
      this.deltaX = (this.deltaX / this.stripeWidth) * 100;

      if (typeof this.scrolling == "undefined" ) {
        this.scrolling = Math.abs(this.startX - e.touches[0].pageX) < Math.abs(e.touches[0].pageY - this.startY);
      }

      if (this.scrolling) {
        return
      }

      // if first or last slide add rubber-band-like resistance
      if ((this.startX - e.touches[0].pageX) < 0 && this.current > 0 || (this.startX - e.touches[0].pageX) > 0 && this.current < this.max - 1) {
        this._translateX(base + this.deltaX);
      } else {
        this._translateX(base + (this.deltaX) / 3.5);
      }
      e.preventDefault();
    },
    _generateNav: function(max) {
      var i, html = "<div class='" + this.options.cssNav + "'>";

      for (i = 0; i < max; i++) {
        html += "<a data-slide='" + i + "' href='#' aria-label='slide " + i + "'></a>";
      }

      return html + "</div>";
    },
    _animationStart: function() {
      var that = this;
      this.$elem.addClass("in-motion");
      this.isAnimated = true;

      // moving from the first frame to the last one
      // if _setSpeed is called immediately it messes up the transition
      if (this.options.seamless && this.target === this.max-1 && this.current === 0) {
        this._setSpeed(0);
        this._resetPosition(this.max-1);
        window.setTimeout(function(){
          that._setSpeed();
        }, 4);
        this.target = this.max-2;
      }

      if ($.isFunction(this.options.animationStart)) {
        this.options.animationStart.call(this);
      }
    },
    _animationEnd: function() {
      var that = this;
      this.$elem.removeClass("in-motion");

      // reset to the first frame
      if (this.options.seamless && this.current === this.max-1) {
        this._setSpeed(0);
        this.current = 0;
        this._resetPosition();
        window.setTimeout(function() {
          that._setSpeed();
          that.isAnimated = false;
        }, 50);
      } else {
        this.isAnimated = false;
      }

      if ($.isFunction(this.options.animationEnd)) {
        this.options.animationEnd.call(this);
      }

    }
  }

  $.fn.tlrkSlide = function(option) {
    return this.each(function() {
      var $this = $(this),
      data = $this.data("tlrkSlide"),
      options = $.extend({}, $.fn.tlrkSlide.defaults, typeof option == "object" && option),
      action = typeof option == "string" ? option : options.slide

      if (!data) {
       $this.data("tlrkSlide", (data = new TlrkSlide(this, options)));
     }

     if (typeof option === "number") {
      data.go(option);
    }
    else if (action) {
      data[action]();
    }
  })
  }

  $.fn.tlrkSlide.defaults = {
    activeDotClass: "is-active",
    cssWrap: "ts-wrap",
    cssStrip: "ts-strip",
    cssNav: "ts-nav",
    slides: ".slide",
    loop: false,
    play: false,
    speed: 300,
    playTimeout: 5000,
    stopOnHover: true,
    generateNav: false,
    nav: false,
    seamless: false
  }

})( jQuery, window , document );
