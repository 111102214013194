import $ from 'jquery';

(function sectionbg() {
  if (!$.support.cssProperty('object-fit')) {
    $('.Section-bg').each(function eachSectionBg() {
      const $container = $(this);
      const imgUrl = $container.find('img').prop('src');
      const video = $container.find('video');
      if (imgUrl) {
        $container
          .css('backgroundImage', `url(${imgUrl})`)
          .addClass('compat-object-fit');
      }
      else if (video.length > 0) {
        $container
          .addClass('compat-object-fit-video')
          .closest('.Section').addClass('u-oh');
      }
    });
  }
})();
