import $ from 'jquery';

(function pricingsInit() {
  if (!$('.Pricings').length) {
    return;
  }

  $('.Pricings-title').each((i, value) => {
    if ($(value).text().length > 22) {
      $('.Pricings-title').css({ 'margin-bottom': '75px' });
    }
  });

  function equalCols(container, col) {
    container.each(function eachContainer() {
      let highestBox = 0;
      $(col, this).each(function eachCol() {
        if ($(this).height() > highestBox) {
          highestBox = $(this).height();
        }
      });
      $(col, this).height(highestBox);
    });
  }

  let resizeTimer;

  $(window).on('resize', () => {
    $('.js-pricing-list-products > .Bullet1').css({
      height: ''
    });
    $('.js-pricing-list-support .Bullet1').css({
      height: ''
    });
    equalCols($('.container'), '.js-pricing-list-products > .Bullet1');
    equalCols($('.container'), '.js-pricing-list-support .Bullet1');

    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      if ($(window).width() < 960) {
        $('.js-pricing-list-products > .Bullet1').css({
          height: ''
        });
        $('.js-pricing-list-support .Bullet1').css({
          height: ''
        });
      }
      else {
        equalCols($('.container'), '.js-pricing-list-products > .Bullet1');
        equalCols($('.container'), '.js-pricing-list-support .Bullet1');
      }
    }, 150);
  });


  $('#backToBundles').click(() => {
    setTimeout(() => {
      $(window).trigger('resize');
    }, 300);
  });

  $(document).ready(() => {
    if ($(window).width() > 960) {
      equalCols($('.container'), '.js-pricing-list-products > .Bullet1');
      equalCols($('.container'), '.js-pricing-list-support .Bullet1');
    }
  });

  const $sticky = $('.sticky');
  const $stickyContainer = $('.sticky-container');
  const $stickyContainerOffset = $stickyContainer.offset();
  const $start = $stickyContainerOffset.top;
  const topOfElement = $('.js-pricings-bundle').offset().top;
  const bottomOfElement = $('.js-pricings-bundle').offset().top + $('.js-pricings-bundle').outerHeight();
  const bottomOfScreen = $(window).scrollTop() + $(window).height();
  const topOfScreen = $(window).scrollTop();

  let resizeTimerScroll;

  $(window).scroll(() => {
    const stickyContainerHeight = $('.sticky-container').height();

    clearTimeout(resizeTimerScroll);
    resizeTimerScroll = setTimeout(() => {
      const stickyRect = $('.sticky-container')[0].getBoundingClientRect();

      if ((bottomOfScreen > topOfElement) && (topOfScreen < bottomOfElement)) {
        $('.Pricings-sticky-box').removeClass('u-dn');
      }
      else {
        $('.Pricings-sticky-box').addClass('u-dn');
      }

      if ($(window).scrollTop() > $start - 10 && stickyRect.top < 0 && stickyRect.top > -Math.abs(stickyContainerHeight)) {
        $('.Pricings-sticky-box').removeClass('u-dn');

        $sticky.each((index, element) => {
          $(element).css({
            position: 'fixed',
            top: 0,
            width: $(element).parent().outerWidth(),
            left: $(element).parent().offset().left
          });
        });
      }
      else {
        $('.Pricings-sticky-box').addClass('u-dn');
        $sticky.css('position', '');
      }
    }, 80);
  });
})();
