/**
 * Debounce a function with setTimeout
 *
 * @param {Function} fn - the function to debounce
 * @param {Number} time - time interval in milliseconds
 * @return {Function} debouncedFn - the new debounced function
 */
function debounceTimeout(fn, time) {
  let timeout;

  return function debouncedFn(...args) {
    if (timeout) {
      clearTimeout(timeout);
    }

    // const ctx = this;
    // timeout = setTimeout(() => fn.apply(ctx, args), time);

    timeout = setTimeout(fn, time, ...args);
  };
}

/**
 * Debounce a function with requestAnimationFrame
 *
 * @param {Function} fn - the function to debounce
 * @return {Function} debouncedFn - the new debounced function
 */
function debounceRaf(fn) {
  let timeout;

  return function debouncedFn(...args) {
    if (timeout) {
      cancelAnimationFrame(timeout);
    }

    // const ctx = this;
    // timeout = requestAnimationFrame(() => fn.apply(ctx, args));

    timeout = requestAnimationFrame(() => fn(...args));
  };
}

/**
 * Debounce a function with either setTimeout or requestAnimationFrame
 * By default requestAnimationFrame is used if time is not given as argument
 *
 * @param {Function} fn - the function to debounce
 * @param {Number} time - time interval in milliseconds
 * @return {Function} result - the new debounced function
 */
export function debounce(fn, time) {
  if (time == null) {
    return debounceRaf(fn);
  }
  return debounceTimeout(fn, time);
}
