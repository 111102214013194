import $ from 'jquery';
import { interactive } from './interactive.js';

(function formsMvcRevealClosure() {
  /**
   * MVC form field wrappers have more elements in them
   * like labels, error messages and other hidden input fields
   * we should wrap only the input[type="password"] field in order to have "has-reveal" plugin working correctly
   */
  const $passwordInputs = $('.js-has-reveal input[type="password"]');

  if (!$passwordInputs.length) {
    return;
  }

  $passwordInputs.each(function each() {
    const $input = $(this);
    const $revealWrap = $('<div class="has-reveal" />');
    $input.wrap($revealWrap);
  });
})();


(function formsHasRevealClosure() {
  const $revealWrap = $('.has-reveal');

  if (!$revealWrap.length) {
    return;
  }

  $revealWrap.each(function eachRevealWrap() {
    const $this = $(this);
    const $revealEye = $(document.createElement('span'));
    const $revealPass = $(document.createElement('div'));
    const $password = $this.find('input[type="password"]');

    // create a revealing password
    $revealPass.addClass('reveal-pass u-dn').appendTo($this);

    // create an eye
    $revealEye.addClass('reveal-eye').appendTo($this);

    // clicking on the eye events
    $revealEye.on('mousedown', () => {
      $revealPass.text($password.val()).removeClass('u-dn');
    });

    $revealEye.on('mouseup mouseleave', () => {
      $revealPass.addClass('u-dn');
    });

    // show the eye, only if there is text in password field
    $password.on('keyup', () => {
      if ($password.val().length > 0) {
        $revealEye.addClass('is-visible');
      }
      else {
        $revealEye.removeClass('is-visible');
      }
    });
  });
})();


/**
 * @class RevealPassword
 * v2 new design, behavior and implementation
 */
class RevealPassword {
  /**
   * @constructor
   * @param {HTMLInputElement} input - input[type="password"] element
   */
  constructor(input) {
    if (!(input instanceof HTMLInputElement)) {
      throw new Error('input[type="password"] is required');
    }

    this.input = input;

    // div wrapper with position relative
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('RevealPasswordWrapper');

    // button to toggle the state
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerText = 'Show';
    this.button.classList.add('RevealPasswordButton');

    // wrap the input[type="password"] element
    this.input.parentNode.insertBefore(this.wrapper, this.input);
    this.wrapper.append(this.input);
    this.wrapper.append(this.button);

    // add button click handler
    this.button.addEventListener('click', event => this.toggleOnClick(event));
  }

  /**
   * @public
   * Toggle state - show or hide the password
   */
  toggle() {
    if (this.input.type === 'password') {
      this.input.type = 'text';
      this.button.innerText = 'Hide';
      this.button.classList.add('is-pressed');
    }
    else {
      this.input.type = 'password';
      this.button.innerText = 'Show';
      this.button.classList.remove('is-pressed');
    }
  }

  /**
   * @private
   * Toggle state event handler
   */
  toggleOnClick(event) {
    this.toggle();
    event.preventDefault();
  }

  /**
   * @public
   * Init all given elements
   */
  static init() {
    Array.from(document.querySelectorAll('.js-RevealPassword input[type="password"]')).map(input => new RevealPassword(input));
  }
}

// init on dom ready
interactive().then(() => {
  RevealPassword.init();
});
