import { debounce } from './debounce.js';
import { interactive } from './interactive.js';

/**
 * Improved <select> menu navigation
 *
 * Add Escape key support
 * Add better accessibility keyboard support
 */

/**
 * Example
 *
 * <select id="js--some-id" class="js--select-menu-nav" required>
 *   <option value="/webinars" disabled? selected? hidden?>Filter by Product</option>
 *   <optgroup label="End-to-end Solutions">
 *     <option value="/webinars/devcraft">Test Devcraft</option>
 *   </optgroup>
 *   <optgroup label="Web">
 *     <option value="/webinars/kendo-ui">Progress Kendo UI</option>
 *     <option value="/webinars/blazor-ui">Telerik UI for Blazor</option>
 *     <option value="/webinars/aspnet-mvc">MVC override title</option>
 *   </optgroup>
 *   <optgroup label="Other">
 *     <option value="/webinars/fiddler">Fiddler Classic</option>
 *     <option value="/webinars/fiddler-everywhere">Fiddler Everywhere</option>
 *     <option value="/webinars/fiddler-jam">Fiddler Jam</option>
 *     <option value="/webinars/services">Services</option>
 *     <option value="/webinars/teampulse">TeamPulse</option>
 *     <option value="/webinars/justmock">Telerik JustMock</option>
 *     <option value="/webinars/platform">Telerik Platform</option>
 *     <option value="/webinars/appbuilder">Telerik Platform AppBuilder</option>
 *     <option value="/webinars/report-server">Telerik Report Server</option>
 *     <option value="/webinars/reporting">Telerik Reporting</option>
 *     <option value="/webinars/aspnet-ajax">Telerik UI for ASP.NET AJAX</option>
 *     <option value="/webinars/aspnet-core-ui">Telerik UI for ASP.NET Core</option>
 *     <option value="/webinars/jsp-ui">Telerik UI for JSP</option>
 *     <option value="/webinars/php-ui">Telerik UI for PHP</option>
 *     <option value="/webinars/winforms">Telerik UI for WinForms</option>
 *     <option value="/webinars/wpf">Telerik UI for WPF</option>
 *     <option value="/webinars/xamarin-ui">Telerik UI for Xamarin</option>
 *     <option value="/webinars/teststudio">Test Studio</option>
 *     <option value="/webinars/testing-framework">Testing Framework</option>
 *     <option value="/webinars/uwp">UI for Universal Windows Platform</option>
 *   </optgroup>
 * </select>
 */

/**
 * @todo - fix firefox
 *
 * Firefox <select> menu is really dumb
 *
 * Step to reproduce:
 *
 * 1 -> Expand the select menu -> this is 1 click
 * 2 -> Move between items with UP/DOWN arrow keys
 * 3 -> Click outside the select menu
 *
 * Result -> Firefox is actually registering 2 click events, when in fact it should be only 1!
 * Expected behaviour -> Firefox should register only 1 click event in this case!
 * Tested with Firefox v84.0.1 (64-bit)
 *
 */

/**
 * @class SelectMenuNav
 */
class SelectMenuNav {
  /**
   * @constructor
   * @param {HTMLSelectElement} element - the <select> element
   * @param {Object} options - options object
   * @param {Number} [options.time] - time in ms used for the debounced change handler
   */
  constructor(element, { time = 512 } = {}) {
    if (!element) {
      throw new Error('[SelectMenuNav] HTMLSelectElement is required');
    }

    if (!(element instanceof HTMLSelectElement)) {
      throw new Error('[SelectMenuNav] HTMLSelectElement is required');
    }

    this.element = element;
    this.initialIndex = element.selectedIndex;
    this.clickCount = 0;
    this.isMouseIn = null;

    this.reset = this.reset.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeDebounced = debounce(this.onChange, time);

    this.element.addEventListener('blur', this.onBlur, false);
    this.element.addEventListener('click', this.onClick, false);
    this.element.addEventListener('focus', this.onFocus, false);
    this.element.addEventListener('keyup', this.onKeyUp, false);
    this.element.addEventListener('change', this.onChangeDebounced, false);
  }

  reset() {
    this.clickCount = 0;
    this.element.selectedIndex = this.initialIndex;
  }

  // do not fire document click when select is clicked
  /* eslint class-methods-use-this: 0 */
  onClick(event) {
    this.clickCount++;

    // + click on select
    // + click on the option
    if (this.clickCount % 2 === 0) {
      document.removeEventListener('click', this.reset);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  // when you click outside the select element
  // by default it will change to the hovered option
  onBlur() {
    this.clickCount = 0;
    setTimeout(() => document.removeEventListener('click', this.reset), 128);
  }

  onFocus() {
    this.clickCount = 0;
    document.addEventListener('click', this.reset, { once: true, capture: false, passive: true });
  }

  // keydown is not fired with select el
  onKeyUp(event) {
    // Escape
    if (event.keyCode === 27) {
      this.reset();
    }
  }

  // on change - change the location
  onChange() {
    const { selectedIndex } = this.element;
    const { value } = this.element.options[selectedIndex];

    if (value !== window.location.pathname) {
      window.location.assign(value);
    }

    document.removeEventListener('click', this.reset);
  }
}

/**
 * init on dom ready
 */
interactive().then(() => Array.from(document.querySelectorAll('.js--select-menu-nav')).map(select => new SelectMenuNav(select)));
