import $ from 'jquery';

(function init() {
  const $btn = $('.js-go-back');

  if ($btn.length < 1) {
    return;
  }

  function back() {
    window.history.back();
    return false;
  }

  $btn.on('click.back', back);
})();
