import $ from 'jquery';
import SocialShare from '@progress-wad/social-share';

const pluginName = 'sharer';

$.fn[pluginName] = function sharerPlugin(options) {
  return this.each(function each() {
    if (!$.data(this, `plugin_${pluginName}`)) {
      $.data(this, `plugin_${pluginName}`, new SocialShare({
        element: this,
        ...options
      }));
    }
  });
};
