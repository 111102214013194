import { Toggle } from './toggle.js';

const defaultOptions = {
  activeClass: 'is-active',
  itemsSelector: 'a, button'
};

const pluginName = 'SwitchNav';

class SwitchNav {
  constructor(element, options) {
    this.element = element;
    this.options = {
      ...defaultOptions,
      ...options
    };
    this.navItems = Array.from(element.querySelectorAll(this.options.itemsSelector));
    this.activeClass = this.options.activeClass;
    [this.activeItem] = this.navItems;

    this.navItems.forEach(item => {
      item.addEventListener('click', () => this.setActiveItem(item));
    });

    this.hashChangeHandler();
    window.addEventListener('hashchange', this.hashChangeHandler.bind(this));
  }

  hashChangeHandler() {
    this.hashArgv = Toggle.parseHash();
    if (this.hashArgv === null) return;
    const navItem = this.navItems.find(item => this.hashArgv.includes(item.dataset.hash));
    if (!navItem) return;
    if (this.activeItem === navItem) return;
    if (navItem) navItem.dispatchEvent(new Event('click', { bubbles: true }));
  }

  setActiveItem(targetItem) {
    this.navItems.forEach(item => {
      if (targetItem === item) {
        item.classList.add(this.options.activeClass);
        this.activeItem = item;
        return;
      }

      item.classList.remove(this.options.activeClass);
    });

    const activeItemHash = this.activeItem.dataset.hash;

    if (targetItem.dataset.hash && (!this.hashArgv || !this.hashArgv.includes(activeItemHash))) {
      window.location.hash = activeItemHash;
    }
  }
}

$.fn[pluginName] = function(options) {
  return this.each(function() {
    if (!$.data(this, `plugin_${pluginName}`)) {
      new SwitchNav(this, options);
    }
  });
};
