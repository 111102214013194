import $ from 'jquery';

// # Dropdown jquery plugin
// # Depends on "_dropdown.sass" and array shim
// # open "dropdown-view" on btn (link, span) click
// # close on body click and escape
// # option for visible 'dropdown-view'
// # option for center the view - this could be done with css too

const pluginName = 'dropdown';
const dropdownsList = [];
const TRANSITIONS = $.support.cssProperty('transition');
const TRANSITION_END = 'transitionend otransitionend webkitTransitionEnd';

export default function DropdownPlugin(element, options) {
  this.$o = $({}); // pub/sub helper for custom events published by this instance only
  this._name = pluginName;
  this.element = element;
  this.$element = $(element);
  this.options = $.extend({}, $.fn[pluginName].defaults, options);
  this.$trigger = this.$element.find(`.${this.options.btnClass}`);
  this.$content = this.$element.find(`.${this.options.contentClass}`);
  this.init();
}

DropdownPlugin.prototype = {
  init() {
    this.addEvents();
    if (this.options.closed === false) {
      this.open();
    }
  },
  open() {
    $.fn[pluginName].closeAll();
    this.isOpen = true;
    this.$element.addClass('is-open');
    this.$trigger.addClass('is-active');
    this.publishEventOnTransitionEnd('/dropdown/open');
    $(document.body).on('click.dropdown', $.proxy(this.close, this));
    $(document).on('keydown.dropdown', $.proxy(this.closeByEsc, this));
  },
  close() {
    this.isOpen = false;
    this.$element.removeClass('is-open');
    this.$trigger.removeClass('is-active');
    this.publishEventOnTransitionEnd('/dropdown/close');
    $(document.body).off('click.dropdown');
    $(document).off('keydown.dropdown');
  },
  closeByEsc(e) {
    if (e.keyCode === 27) {
      this.close();
    }
  },
  toggle(e) {
    if (this.isOpen) {
      this.close();
    }
    else {
      this.open();
    }
    e.stopPropagation();
    e.preventDefault();
  },
  center() {
    if (!this.options.centerContent) {
      return;
    }
    const btnWidth = this.$trigger.outerWidth();
    const contentWidth = this.$content.outerWidth();
    this.$content.css('left', -(Math.abs(btnWidth - contentWidth) / 2));
  },
  addEvents() {
    const { stopPropagation } = this.options;
    this.center();
    this.$trigger.on('click.dropdown', $.proxy(this.toggle, this));
    this.$content.on('click.dropdown', e => {
      if (stopPropagation) {
        e.stopPropagation();
      }
    });
  },
  subscribe() {
    // eslint-disable-next-line
    this.$o.on.apply(this.$o, arguments);
  },
  unsubscribe() {
    // eslint-disable-next-line
    this.$o.off.apply(this.$o, arguments);  },
  publish() {
    // eslint-disable-next-line
    this.$o.trigger.apply(this.$o, arguments);
  },
  publishEventOnTransitionEnd(eventName) {
    if (TRANSITIONS) {
      this.$content.one(TRANSITION_END, $.proxy(this.publish, this, eventName));
    }
    else {
      this.publish(eventName);
    }
  }
};

$.fn[pluginName] = function dropdownPlugin(options) {
  return this.each(function each() {
    if (!$.data(this, `plugin_${pluginName}`)) {
      dropdownsList.push($.data(this, `plugin_${pluginName}`, new DropdownPlugin(this, options)));
    }
  });
};

$.fn[pluginName].defaults = {
  wrapClass: 'Dropdown',
  btnClass: 'Dropdown-control',
  contentClass: 'Dropdown-view',
  stopPropagation: true,
  centerContent: false,
  closed: true
};

// global function to close all dropdowns
$.fn[pluginName].closeAll = function closeAllDropdowns() {
  dropdownsList.forEach(dropdown => {
    if (dropdown.isOpen) {
      dropdown.close();
    }
  });
};
