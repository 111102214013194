// On a page with a param ?utm_term=Text the element ".js-queryreplace-utm_term" will change its content to "Text", can work with ANY query
// On a page with a param ?product=kendo-ui the <select> tag wrapped in ".js-queryselect-product" will pick <option id="kendo-ui">
// On a page with a param ?type=online the <select> tag wrapped in ".js-queryselect-type" will pick <option value="Online">
// On a page with a param ?type=invoice the <input type="radio" value="invoice">, wrapped in ".js-queryradio-type" will be chacked

window.addEventListener('DOMContentLoaded', () => {
  const replaceElements = document.querySelectorAll('[class*="js-query"]');
  if (replaceElements.length === 0 || !window.location.search) return;

  replaceElements.forEach(el => {
    const currentClass = Array.from(el.classList).filter(x => x.includes('js-query'))[0];
    const queryParameterValue = new URL(window.location).searchParams.get(currentClass.split('-').splice(2).join('-'));
    if (!queryParameterValue) return;

    if (currentClass.includes('js-queryselect')) {
      const selectEl = el.querySelector('select');
      const optionId = selectEl.querySelector(`select [id="${queryParameterValue}"]`);

      if (optionId) { // pre-select by option id
        optionId.selected = true;
      }
      else { // pre-select by option value
        selectEl.querySelectorAll('option').forEach(option => {
          if (queryParameterValue === option.value.toLowerCase().replace(/(\s)|(\/)|(\.)|(,)|(#)/g, '-')) option.selected = true;
        });
      }

      selectEl.dispatchEvent(new Event('change'));
    }

    if (currentClass.includes('js-queryradio')) {
      el.querySelector(`input[value="${queryParameterValue}"]`).checked = true;
    }

    if (currentClass.includes('js-queryreplace')) {
      el.innerText = queryParameterValue;
    }
  });
});
