import $ from 'jquery';

(function vibeSidebar() {
  const qs = document.querySelector.bind(document);
  const sidebar = qs('.js-sticky-sidebar');
  const pane = qs('#js-scroll-view-pane');
  const arrows = {
    up: qs('#js-sidebar-arrow-up'),
    down: qs('#js-sidebar-arrow-down')
  };

  if (!sidebar || $('#js-no-sticking').length) {
    return;
  }

  function highlightFirst() {
    qs('#js-sidebar-menu li:first-child a').classList.add('is-active');
  }

  function followActiveLink() {
    const sideBarLinks = $('#js-sidebar-menu a');
    sideBarLinks.classChange(() => {
      const sideBarActiveLink = sideBarLinks.filter('.is-active');
      if (sideBarActiveLink.length > 0) {
        if (sideBarActiveLink.parent().index() === 0) {
          $(pane).animate({ scrollTop: 0 }, { queue: false, duration: 1000 });
        }
        else {
          const top = sideBarActiveLink.position().top - (($(pane).height() - sideBarActiveLink.height()) / 3);
          $(pane).animate({ scrollTop: top }, { queue: false, duration: 1000 });
        }
      }
      else {
        highlightFirst();
      }
    });
  }

  function isScrollable(element) {
    return element.scrollHeight > element.clientHeight;
  }

  function fixIE() {
    if (isScrollable(sidebar)) {
      sidebar.classList.add('SidebarVibe--IE');
    }
  }

  function scrollDown(event) {
    const step = pane.clientHeight / 2;
    $(pane).stop().animate({ scrollTop: pane.scrollTop + step }, 240, 'swing');
    event.preventDefault();
  }

  function scrollUp(event) {
    const step = pane.clientHeight / 2;
    $(pane).stop().animate({ scrollTop: pane.scrollTop - step }, 240, 'swing');
    event.preventDefault();
  }

  function toggleArrows() {
    const top = pane.scrollTop;
    const bottom = pane.scrollHeight - (pane.clientHeight + top);
    $(arrows.up).toggleClass('is-active', top > 10);
    $(arrows.down).toggleClass('is-active', bottom > 10);
  }

  function initArrows() {
    arrows.up.addEventListener('click', scrollUp, false);
    arrows.down.addEventListener('click', scrollDown, false);
  }

  function init() {
    fixIE();

    if (arrows.up && arrows.down) {
      followActiveLink(); // make sure there's always an active item into view
      highlightFirst(); // highlight the first link initially
      initArrows();
      toggleArrows();
      pane.addEventListener('scroll', toggleArrows, false);
      window.addEventListener('resize', toggleArrows, false);
    }
  }

  init();
})();
