/**
 * js for dropdown filters
 * https://www.telerik.com/videos
 */
import { interactive } from './interactive.js';

/**
 * query selector utility fns
 */
const qs = (selector, parent = document) => parent.querySelector(selector);
const qsa = (selector, parent = document) => Array.from(parent.querySelectorAll(selector));

/**
 * js labels for dropdowns
 */
interactive().then(() => {
  const labels = Array.from(document.querySelectorAll('.js-dropdown-label'));

  function onLabelClick(event) {
    const label = event.target;
    const forAttr = label.getAttribute('for');
    const dropdown = document.querySelector(`#${forAttr}`);
    const button = dropdown && dropdown.querySelector('.Dropdown-control');

    if (button) {
      setTimeout(() => button.click());
    }
  }

  labels.forEach(label => label.addEventListener('click', onLabelClick, false));
});

/**
 * focus filter list input, when dropdown is opened
 */
interactive().then(() => {
  const buttons = Array.from(document.querySelectorAll('.Dropdown--Select .Dropdown-control'));

  function focusFilterListInput(event) {
    const button = event.target;
    const dropdown = button.parentElement.parentElement;
    const filterInput = dropdown.querySelector('.Dropdown-filter-list-input');

    if (filterInput) {
      setTimeout(() => filterInput.focus());
    }
  }

  buttons.forEach(button => {
    button.addEventListener('focus', focusFilterListInput, false);
    button.addEventListener('click', focusFilterListInput, false);
  });
});

/**
 * Dropdown selection actions
 */
interactive().then(() => {
  const dropdowns = qsa('.Dropdown');
  const filtersForm = qs('#js-filters-section') || qs('.js-filters-section');
  const activeFilters = qsa('#js-filters-in-use button[type="button"], .js-filter-results button[type="button"]');

  function scrollToFiltersFormIfNeeded() {
    const { search } = window.location;
    if (search && filtersForm) {
      window.history.scrollRestoration = 'manual';
      filtersForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  function onFilterSelect(event) {
    const dropdown = this; // dropdown div
    const button = event.target;

    if (button.tagName !== 'BUTTON') {
      return;
    }

    const { url } = button.dataset;
    const dropdownButton = qs('.Dropdown-control', dropdown);

    button.classList.add('is-selected');
    dropdownButton.innerText = button.innerText;
    window.location.href = url;
  }

  function onFilterRemove() {
    const button = this;
    const { url } = button.dataset;
    const span = button.parentElement;
    span.remove();
    window.location.href = url;
  }

  function init() {
    scrollToFiltersFormIfNeeded();
    dropdowns.forEach(dropdown => dropdown.addEventListener('click', onFilterSelect, false));
    activeFilters.forEach(filter => filter.addEventListener('click', onFilterRemove, false));
  }

  init();
});
