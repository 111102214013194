(() => {
  const lazyEl = Array.from(document.querySelectorAll('.lazyload'));

  if (lazyEl.length === 0) return;

  const load = el => {
    if (el.classList.contains('no-mobile') && window.innerWidth < 590) {
      el.remove();
      return;
    }
    if (el.hasAttribute('data-src')) {
      el.setAttribute('src', el.getAttribute('data-src'));
    }
    else if (el.hasAttribute('data-srcset')) {
      el.setAttribute('srcset', el.getAttribute('data-srcset'));
    }
  };

  if (!('IntersectionObserver' in window)) {
    lazyEl.forEach(el => load(el));
    return;
  }

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        load(entry.target);
        observer.unobserve(entry.target);
      }
    });
  }, { rootMargin: '200px' });

  lazyEl.forEach(el => observer.observe(el));
})();
