import { debounce } from './debounce.js';

window.addEventListener('DOMContentLoaded', () => {
  const paddleScrollers = document.querySelectorAll('.js-paddle-scroller');
  const paddleButtons = document.querySelectorAll('.js-paddle-left, .js-paddle-right');
  if (paddleScrollers.length === 0 || paddleButtons.length === 0) return;

  function calculatePaddles() {
    paddleScrollers.forEach(el => {
      const currentScroll = Math.ceil(el.scrollLeft);
      const elParent = el.parentElement;
      const elScrollWidth = el.scrollWidth;
      const elOffsetWidth = el.offsetWidth;
      const paddleLeft = elParent.querySelector('.js-paddle-left');
      const paddleRight = elParent.querySelector('.js-paddle-right');

      // are paddles needed right now?
      if (elScrollWidth !== elOffsetWidth) {
        paddleLeft.classList.remove('u-dn');
        paddleRight.classList.remove('u-dn');
      }
      else {
        paddleLeft.classList.add('u-dn');
        paddleRight.classList.add('u-dn');
        return;
      }

      // enable/disable paddles
      if (currentScroll === 0) {
        paddleLeft.setAttribute('disabled', '');
        paddleRight.removeAttribute('disabled');
      }
      else if (currentScroll === elScrollWidth - elOffsetWidth) {
        paddleLeft.removeAttribute('disabled');
        paddleRight.setAttribute('disabled', '');
      }
      else {
        paddleLeft.removeAttribute('disabled');
        paddleRight.removeAttribute('disabled');
      }
    });
  }

  // listeners
  paddleScrollers.forEach(el => {
    el.addEventListener('scroll', debounce(calculatePaddles, 25));
  });
  paddleButtons.forEach(el => {
    const relatedScroller = el.parentElement.querySelector('.js-paddle-scroller');
    el.addEventListener('click', () => {
      relatedScroller.scroll({
        left: Math.round(relatedScroller.scrollLeft) + (el.classList.contains('js-paddle-left') ? (-200) : (200)),
        behavior: 'smooth'
      });
    });
  });
  window.addEventListener('resize', debounce(calculatePaddles, 200));

  // init
  calculatePaddles();
});
