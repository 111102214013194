import $ from 'jquery';

(function fileUploadClosure() {
  const pluginName = 'fileupload';

  function Plugin(element, options) {
    this._name = pluginName;
    this.element = element;
    this.$element = $(element);
    this.options = $.extend({}, $.fn[pluginName].defaults, options);
    this.init();
  }

  Plugin.prototype = {
    init() {
      this.$inputElem = this.$element.find(`.${this.options.inputClass}`);
      this.$valueElem = this.$element.find(`.${this.options.inputValueClass}`);
      this.attachEvents();
    },
    onChangeHanlder() {
      this.$valueElem.text(this.$inputElem.val().replace('C:\\fakepath\\', ''));
    },
    attachEvents() {
      this.$inputElem.on('change', $.proxy(this.onChangeHanlder, this));
    }
  };

  $.fn[pluginName] = function fileUploadPlugin(options) {
    return this.each(function each() {
      if (!$.data(this, `plugin_${pluginName}`)) {
        $.data(this, `plugin_${pluginName}`, new Plugin(this, options));
      }
    });
  };

  $.fn[pluginName].defaults = {
    wrapperClass: 'FileUpload',
    inputClass: 'FileUpload-input',
    inputValueClass: 'FileUpload-value'
  };
})();
