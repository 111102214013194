import $ from 'jquery';

$(() => {
  if ($('.ImagesSlider').length < 1) return;

  $('.ImagesSlider').each((i, el) => {
    const $first = $(el).find('li').first();
    const $second = $first.next('li');

    $first.addClass('active');

    if ($second.length === 0) {
      $(el).find('.SliderControls').hide();
    }
    else {
      $(el).find('.js-prev-image').hide();
      $second.addClass('next-active');
    }
  });

  $('.SliderControls').on('click', '.js-next-image', e => {
    e.preventDefault();
    const $cont = $(e.target).closest('.SliderControls').parent('.ImagesSlider');
    const $oldActive = $cont.find('li.active').first();
    const $oldPrev = $cont.find('li.prev-active').first();
    const $oldNext = $cont.find('li.next-active').first();
    const $next = $oldNext.next('li');

    if ($next.length === 0) $cont.find('.js-next-image').fadeOut(100);

    $oldPrev.removeClass('prev-active');
    $oldActive.removeClass('active').addClass('prev-active');
    $oldNext.removeClass('next-active').addClass('active');
    $next.addClass('next-active');
    $cont.find('.js-prev-image').fadeIn();
  });

  $('.SliderControls').on('click', '.js-prev-image', e => {
    e.preventDefault();
    const $cont = $(e.target).closest('.SliderControls').parent('.ImagesSlider');
    const $oldActive = $cont.find('li.active').first();
    const $oldPrev = $cont.find('li.prev-active').first();
    const $oldNext = $cont.find('li.next-active').first();
    const $prev = $oldPrev.prev('li');

    if ($prev.length === 0) $cont.find('.js-prev-image').fadeOut(100);

    $oldNext.removeClass('next-active');
    $oldPrev.removeClass('prev-active').addClass('active');
    $oldActive.removeClass('active').addClass('next-active');
    $prev.addClass('prev-active');
    $cont.find('.js-next-image').fadeIn();
  });
});
