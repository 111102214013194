import $ from 'jquery';

$.fn.classChange = function jqueryClassChange(callback) {
  const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

  if (!MutationObserver) {
    return;
  }

  const options = {
    subtree: false,
    attributes: true,
    attributeFilter: ['class']
  };

  const observer = new MutationObserver(mutations => {
    mutations.forEach(e => {
      callback.call(e.target);
    });
  });

  this.each(function each() {
    observer.observe(this, options);
  });
};
