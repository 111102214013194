import DropdownPlugin from './dropdown.js';

const pluginName = 'ComboBox';

const defaultOptions = {
  wrapClass: 'ComboBox',
  viewClass: 'ComboBox-view',
  optionsClass: 'ComboBox-item',
  controlClass: 'ComboBox-control',
  optionsActiveClass: 'is-active',
  selectedIndex: 0
};

class ComboBox {
  constructor(element, options) {
    this.element = element;
    this.options = { ...defaultOptions, ...options };
    this.view = this.element.querySelector(`.${this.options.viewClass}`);
    this.optionElements = this.element.querySelectorAll(`.${this.options.optionsClass}`);
    this.control = this.element.querySelector(`.${this.options.controlClass}`);

    this.calcViewMaxHeight();
    this.resetOptions();
    this.setActiveOption(this.options.selectedIndex);
    this.attachEventListeners();
    this.initDropdown();
    if (this.options.observe) {
      document.querySelectorAll(this.options.observe).forEach(el => this.observe(el));
    }
  }

  initDropdown() {
    this.dropdown = new DropdownPlugin(this.element, {
      wrapClass: this.options.wrapClass,
      btnClass: this.options.controlClass,
      contentClass: this.options.viewClass
    });
  }

  attachEventListeners() {
    this.optionElements.forEach((optionEl, index) => optionEl.addEventListener('click', event => this.optionClickHandler(event, index)));
    this.control.addEventListener('click', this.calcViewMaxHeight.bind(this));
  }

  optionClickHandler(event, index) {
    this.setActiveOption(index);

    if (!event.synthetic) {
      const changeEvent = new Event('change');
      changeEvent.index = index;
      this.element.dispatchEvent(changeEvent);
    }
  }

  observe(comboBoxElement) {
    if (!comboBoxElement || comboBoxElement === this.element) return;
    comboBoxElement.addEventListener('change', e => {
      const option = this.optionElements[e.index];
      if (!option) return;
      const clickEvent = new Event('click');
      clickEvent.synthetic = true;
      option.dispatchEvent(clickEvent);
    });
  }

  calcViewMaxHeight() {
    this.view.style.maxHeight = `${this.view.scrollHeight}px`;
  }

  resetOptions() {
    this.optionElements.forEach(option => option.classList.remove(this.options.optionsActiveClass));
  }

  setActiveOption(index) {
    const selectedOption = this.optionElements[this.selectedIndex];
    if (selectedOption) {
      selectedOption.classList.remove(this.options.optionsActiveClass);
    }

    const newOption = this.optionElements[index];
    if (newOption) {
      newOption.classList.add(this.options.optionsActiveClass);
    }

    this.control.innerText = newOption.dataset.label;

    if (this.dropdown && this.dropdown.close) {
      this.dropdown.close();
    }

    this.selectedIndex = index;
  }
}

$.fn[pluginName] = function(options) {
  return this.each(function() {
    if (!$.data(this, `plugin_${pluginName}`)) {
      new ComboBox(this, options);
    }
  });
};
