(() => {
  const popoverElements = document.querySelectorAll('[popover]');

  if (popoverElements.length !== 0 && HTMLElement.prototype.hasOwnProperty('popover')) {
    document.addEventListener('pointerdown', e => {
      if (e.target instanceof HTMLElement && e.target.hasAttribute('popover')) {
        popoverElements.forEach(element => {
          element.hidePopover();
        });
      }
    });
  }
})();
