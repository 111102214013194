import $ from 'jquery';

$(() => {
  if (!$('.js-appear').length) {
    return;
  }

  let elScrolling = false;

  const $elements = $('.js-appear').addClass('js-appear-hidden js-appear-reset');
  const viewportGutterTop = 100; // make sure at least this amount of pixels is visible | set 0 not to use this
  const viewportGutterBottom = 100; // make sure at least this amount of pixels is visible | set 0 not to use this

  function calcElPosition(obj) {
    const elPosition = obj.offset().top;
    return [elPosition, elPosition + obj.height()];
  }

  function mScrollHandler() {
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    $.each($elements, (i, obj) => {
      const
        $obj = $(obj);
      const elData = calcElPosition($obj);
      const elTop = elData[0];
      const elBottom = elData[1];
      $(obj).removeClass('js-appear-reset');

      if ((elBottom - viewportGutterBottom) > viewportTop && elTop < (viewportBottom - viewportGutterTop)) {
        // into view
        window.setTimeout(() => {
          $obj.removeClass('js-appear-hidden');
        }, 1);
      }
    });
  }

  // trigger onScroll event
  $(window).on('scroll', () => {
    elScrolling = true;
  });

  window.setInterval(() => {
    if (elScrolling) {
      elScrolling = false;
      mScrollHandler();
    }
  }, 250);

  mScrollHandler();
});
