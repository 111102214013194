;(function ( $, window, document, undefined ) {
  "use strict";

  var pluginName = "switcher",
      defaults = {
        // used css classes
        toggleClass: "is-toggled",
        switcherClass: "Panel-switch",
        resetClass: "Panel-reset",

        // if clicking to the element itself counts, switcherClass is ignored
        self: false,

        // grouping. if selector is provided it will operate with the matched elements
        // and apply the toggleClass to the clicked element
        group: false,

        // how to treat the event
        preventDefault: true,
        stopPropagation: false,

        // if the callback return false the class won't be toggled
        callback: null
      };

  function Plugin ( element, options ) {
    this.element = element;
    this.$element = $(element);
    this.options = $.extend( {}, defaults, options );
    this._name = pluginName;

    this.initialState = this.$element.hasClass(this.options.toggleClass);

    if (this.options.self) {
      this.$element.on("click", $.proxy(this.toggle, this) );
    } else {
      this.$element.on("click", "." + this.options.switcherClass, $.proxy(this.toggle, this));
    }

    this.$element.on("click", "." + this.options.resetClass, $.proxy(this.reset, this));
  }

  Plugin.prototype = {
    toggle: function(e) {
      var options = this.options,
          toggleClass = options.toggleClass,
          groupElement;

      if (options.preventDefault) {e.preventDefault();}
      if (options.stopPropagation) {e.stopPropagation();}

      // if there's callback and it returns false don't do anything
      if ($.isFunction(options.callback) && options.callback.call(this, this) === false) {
        return;
      }

      if (options.group) {
        groupElement = $(e.target).closest(options.group);
        if (groupElement.hasClass(options.toggleClass)) { return; }

        this.$element.find("." + toggleClass).removeClass(toggleClass);
        groupElement.addClass(toggleClass);

      } else {
        this.$element.toggleClass(options.toggleClass);
      }
    },

    // reset to the original state
    reset: function(e) {
      var options = this.options;

      if (options.preventDefault) {e.preventDefault();}
      if (options.stopPropagation) {e.stopPropagation();}

      if (options.group) {
        this.$element.find("." + options.toggleClass).removeClass(options.toggleClass);
      } else {
        this.$element.removeClass(options.toggleClass);
      }
    }
  };

  $.fn[ pluginName ] = function ( options ) {
    return this.each(function() {
      if ( !$.data( this, "plugin_" + pluginName ) ) {
        $.data( this, "plugin_" + pluginName, new Plugin( this, options ) );
      }
    });
  };

})( jQuery, window, document );
