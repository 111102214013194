import $ from 'jquery';

// this entire file should be refactored to replace the similar file here:
// https://www.telerik.com/Frontend-Assembly/Web.Widgets.Accordion/Mvc/Scripts/DisplayAccordion/accordion.js
// this will also allow us to include the lazy load functionality here

(function initAccordion() {
  if ($('.Accordion').length < 1) {
    return;
  }

  const $featuresImages = $('.Accordion-media-image');
  const $featuresLinks = $('.Accordion li');
  const $featureActive = $('.Accordion li.is-active');
  const indexActive = $featuresLinks.index($featureActive);

  $featureActive.find('.Accordion-inner').css({ display: 'block' });

  $featuresImages.eq(indexActive).removeClass('u-dn');

  $('.Accordion-toggle').on('click', function onClick() {
    const $this = $(this);
    const activeIndex = $this.parent().index();

    $featuresImages.each(function each() {
      $(this).addClass('u-dn');
    });
    $featuresImages.eq(activeIndex).removeClass('u-dn');
  });

  $('.Accordion-toggle').on('click', function onClick(e) {
    e.preventDefault();

    const $this = $(this);
    const $thisContent = $this.next();
    const $thisParent = $this.closest('li');
    const $thisParentSiblings = $thisParent.siblings();

    if (!$thisParent.hasClass('is-active')) {
      $thisContent.slideDown(500);
      $thisParent.toggleClass('is-active');
      $thisParentSiblings.removeClass('is-active').find('.Accordion-inner').slideUp(500);
    }
  });

  // lazy load slides, should also check for uncomment
  // $('.Accordion-toggle').one('click', () => {
  //   $('.js-accordion-module-wrapper').uncomment(true);
  // });
})();
