import $ from 'jquery';

if ($('.img-row').length > 0) {
  const globalImageRowInterval = 60000;

  $('.img-row').each(function eachImgRow() {
    $(this).contents().clone().appendTo($(this))
      .end()
      .clone()
      .appendTo($(this));
  });

  (function imgLoop() {
    $('.img-row').each(function eachImgRow() {
      const $this = $(this);
      $this.css({
        transform: `translateX(-${$this.prop('scrollWidth') * 0.666666 + 0}px)`,
        transition: `transform ${globalImageRowInterval}ms linear`
      });
      setTimeout(() => {
        $this.removeAttr('style').css({
          transform: `translateX(-${$this.prop('scrollWidth') * 0.333333 + 0}px)`
        });
      }, globalImageRowInterval);
    });
    setTimeout(() => {
      imgLoop();
    }, globalImageRowInterval);
  })();
}
