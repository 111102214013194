;(function ( $, window, document, undefined ) {
  "use strict";

  var pluginName = "navspy",
      defaults = {
        top: "auto" // takes the container outerHeight
      };

  function Plugin ( element, options ) {
    this.element = element;
    this.$element = $(element);
    this.options = $.extend( {}, defaults, options );
    this._name = pluginName;
    this.$window = $(window);
    this.working = false;
    this.menuItems = this.$element.find("a");

    if (this.options.top === "auto") {
      this.options.top = this.$element.outerHeight();
    }

    // clicks on nav items
    this.menuItems
      .on("click.navspy", $.proxy(this.itemclick, this));

    // attach anchors to corresponding nav links
    this.scrollItems = this.menuItems.map(function() {
      var item = $(this.getAttribute("href"));
      if (item.length) {
        return item;
      }
    });

    // scolling
    this.$window
      .on("scroll.navspy", $.proxy(this.windowscroll, this));
  }

  Plugin.prototype = {
    itemclick: function(e) {
      // clicks on links should scroll smoothly to their anchors
      var $target = $(e.target.getAttribute("href")),
          top;

      // for full browser support animate html AND body
      e.preventDefault();

      // target check
      if (!$target[0]) {
        return;
      }

      // if the element is not visible get the offset.top of the closest .Section
      top = $target.is(":visible") ? $target.offset().top : $target.closest(".Section").offset().top;

      $("html, body").stop().animate({
          scrollTop: (top - this.options.top) + 2
      }, 400);

      // moving the focus for keyboard accessibility currently cannot be done as of 2022, the browser feature that moves the focus intelligently is called "sequential focus navigation starting point" and we break it with preventDefault()
    },

    windowscroll: function() {
      this.scroll = this.$window.scrollTop();
      this.requestTick();
    },

    requestTick: function() {
      if (!this.working) {
        window.requestAnimationFrame($.proxy(this.check, this));
        this.working = true;
      }
    },

    check: function() {
      // Get container scroll position
      var pxBeforeActive = this.options.top,
          fromTop = $(window).scrollTop() + pxBeforeActive;

      // Map scroll items
      var current = this.scrollItems.map(function() {
        if (this.offset().top < fromTop) {
          return this;
        }
      });

      current = current[current.length-1];

      // If an element is in the viewport -> remove active classes and add active class to the current element
      if (current && current.length) {
        var currentID = current[0].id;

        this.menuItems
          .removeClass("is-active")
          .filter("[href=\\#" + currentID + "]")
          .addClass("is-active");
      } else {// If no elements are in the viewport, remove classes
        this.menuItems.removeClass("is-active");
      }

      this.working = false;
    }
  };

  $.fn[ pluginName ] = function ( options ) {
    return this.each(function() {
      if ( !$.data( this, "plugin_" + pluginName ) ) {
        $.data( this, "plugin_" + pluginName, new Plugin( this, options ) );
      }
    });
  };

})( jQuery, window, document );
