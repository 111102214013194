import $ from 'jquery';
import { DataAttrParser } from '@progress-wad/data-attr-parser';

/**
 * Init jquery plugins from data-attrs
 * @todo init vanilla js plugins from data-attr
 */
$(document).ready(() => {
  const elements = Array.from(document.querySelectorAll('[data-tlrk-plugin]'));

  elements.forEach(element => {
    const $element = $(element);
    const plugins = element.getAttribute('data-tlrk-plugin').split(' ');

    plugins.forEach(plugin => {
      if ($.isFunction($.fn[plugin])) {
        const group = plugin.toLowerCase();
        const options = DataAttrParser.parse(element.dataset, [group]);
        $element[plugin](options[group]);
      }
    });
  });
});
