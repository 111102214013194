;(function ( $, window, document, undefined ) {
  "use strict";

  var
      pluginName = "tlrkTabs",
      defaults = {
        wrapperSelector: ".tlrk-tabs", // jquery selector for the wrapper of all tabs
        activeTab: 1,                  // which tab is active by default
        scrollToTabs: true,            // whether to scroll page to the tab-area on click of any tab-link
        automated: false,              // auto rotation of tabs (example usage: testimonials)
        automatedTimer: 5000,          // auto rotation delay between each tab
        automatedFreeze: true,         // freeze auto rotation when mouseover a tab
        setAnchors: true,              // set hash, on click of tabs navigation (you can disable for cases like testimonials)
        animateHeight: false,           // dynamically animate the height of the wrapper while switching between tabs

        // how to treat the event
        preventDefault: true,
        stopPropagation: false
      }
  ;

  function Plugin ( element, options ) {

    this.$element = $(element);
    this.tabLinks = this.$element.find("a");
    this.options = $.extend( {}, defaults, options );
    this.innerWrap = $(this.options.wrapperSelector);
    this.tabs = this.innerWrap.children();
    this.tabsCount = this.tabs.length;
    this.activeTab = parseInt(this.options.activeTab, 10) || 1;
    this.automation = false;

    // change tabs on click
    var $this = this;
    this.tabLinks.on("click", function(e) {
      if(typeof $this.automation !== "undefined" && $this.automation !== false){
        $this._AutomationPause();
      }
      $this.show( e, null );
    });

    // check URL hash
    this._hashcheck();

    // execute initial tab to be shown
    if( this.activeTab && this.activeTab > 0 ) {
      this.show(null, this.activeTab - 1);
    }

    // automate rotation of tabs if needed
    if(this.options.automated) {
      this._autoRotate();
    }

    if (this.options.nextBtn) {
      const nextBtn = document.querySelector(this.options.nextBtn);

      if (nextBtn) nextBtn.addEventListener('click', this._showNextTab.bind(this));
    }

    if (this.options.prevBtn) {
      const prevBtn = document.querySelector(this.options.prevBtn);

      if (prevBtn) prevBtn.addEventListener('click', this._showPrevTab.bind(this));
    }

    var resizing = false;
    $(window).on("resize", function(){ resizing = true; });
    window.setInterval(function() {
      if ( resizing ) {
          resizing = false;
          $this.innerWrap.height( $this.tabs.filter(".is-active").height() );
      }
    }, 800);
  }

  Plugin.prototype = {
    _AutomationPause: function() {
      if(this.automation) {
        window.clearInterval(this.automation);
        this.automation = false;
      }
    },
    _AutomationPlay: function() {
      if(!this.automation) {
        this._initAutomation();
      }
    },
    _initAutomation: function() {
        if(this.automation) {
          window.clearInterval(this.automation);
        }
        var $plugin = this;
        this.automation = window.setInterval(function() {
          $plugin._showNextTab();
        }, this.options.automatedTimer);
    },
    _showPrevTab: function() {
      var selected = this.tabLinks.filter(".is-active").eq(0).index();
      var prev = (selected === 0) ? this.tabLinks.length - 1 : selected - 1;
      this.show(null, prev);
    },
    _showNextTab: function() {
        var selected = this.tabLinks.filter(".is-active").eq(0).index();
        var next = (selected === this.tabLinks.length - 1) ? 0 : selected + 1;
        this.show(null, next);
    },
    _autoRotate: function() {
      // start auto animation
      this._initAutomation();

      var $plugin = this;

      // set mouse hover events if needed
      if(this.options.automatedFreeze) {
          this.innerWrap.on("mouseenter", function() {
            $plugin._AutomationPause();
          });
          this.innerWrap.on("mouseleave", function() {
            $plugin._AutomationPlay();
          });
      }

      // pause automation when plugin is not into view
      var
          scrolling = false,
          scrollHandler = function() {
              var
                tabsData = $plugin._calcPosition(),
                tabsTop = tabsData[0],
                tabsBottom = tabsData[1],
                vTop = $(window).scrollTop(),
                vBottom = vTop + $(window).height()
              ;

              if( tabsBottom > vTop && tabsTop < vBottom ) {
                //into view
                $plugin._AutomationPlay();
              } else {
                //not into view
                $plugin._AutomationPause();
              }
          }
      ;

      $(window).on("scroll", function() {scrolling = true;});
      window.setInterval(function() {
          if ( scrolling ) {
              scrolling = false;
              scrollHandler();
          }
      }, 250);
    },
    _hashcheck: function() {

          // check for URL hash, to override the plugin option for a default starting tab
          var hash = document.location.hash,
              plugin = this;

          if(hash) {
            // if the hash provided matches a tab - set it as active
            hash = plugin.tabLinks.filter("a[href='" + hash + "']").parent().index() + 1;
            plugin.activeTab = ( hash >= 1 ) ? hash : plugin.activeTab;

            window.setTimeout(function(){
              // scroll to tab if there is a hash in the url:
              if( hash >= 1 ) {
                plugin._scrollToItself();
              }
            }, 250);
          }
    },
    _historyEdit: function(newHash) {

          if(typeof newHash === "undefined" || newHash === null ) {
            newHash = "";
          }

          if( newHash.length < 1 ) {
            if (window.history.pushState) {
              window.history.pushState("", document.title, " ");
            } else {
              window.location.hash = "";
            }
            return;
          }

          newHash = new RegExp("#").test(newHash) ? newHash : "#" + newHash;
          if (window.history.replaceState) {
            window.history.replaceState("", document.title, newHash);
          } else {
            window.location.replace(newHash);
          }
    },
    _calcPosition: function() {
      var tabsPosition = ( this.innerWrap.closest(".Section").length > 0 ) ? this.innerWrap.closest(".Section") : this.innerWrap;
      var
          tabsTop = tabsPosition.offset().top;
          return [tabsTop, tabsTop + tabsPosition.height()];
    },
    _scrollToItself: function() {
        var tabsData = this._calcPosition(),
            tabsPosition = tabsData[0];
        $("html,body").animate({scrollTop: tabsPosition}, 400);
    },
    show: function(e, eq) {

      this.tabLinks.removeClass("is-active");
      this.tabLinks.removeAttr('aria-label');

      var
          options = this.options,
          tabClicked = (e) ? $(e.target).addClass("is-active") && $(e.target).attr('aria-label','selected') : this.tabLinks.eq(eq).addClass("is-active") && this.tabLinks.eq(eq).attr('aria-label','selected'),
          tabIndex = eq || this.tabLinks.index(tabClicked)
      ;

      if(e) {
        // this is a click event
        if (options.preventDefault) {e.preventDefault();}
        if (options.stopPropagation) {e.stopPropagation();}

        if (options.scrollToTabs) {
          this._scrollToItself();
        }
        if(options.setAnchors) {
          this._historyEdit(tabClicked.attr("href"));
        }
      }

      this.tabs.removeClass("is-active").eq(tabIndex).addClass("is-active");
      this.tabs.removeAttr('aria-label').eq(tabIndex).attr('aria-label','selected');

      if(options.animateHeight) {
          this.innerWrap.animate({
            height: this.tabs.filter(".is-active").height()
          }, 350);
      }

    } // end of show
  };

  $.fn[ pluginName ] = function ( options ) {
    return this.each(function() {
      if ( !$.data( this, "plugin_" + pluginName ) ) {
        $.data( this, "plugin_" + pluginName, new Plugin( this, options ) );
      }
    });
  };

})( jQuery, window, document );
