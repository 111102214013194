import $ from 'jquery';

(() => {
  const $tabs = $('.js-tab');
  const $panels = $('.js-panel');
  if ($tabs.length < 1 || $panels.length < 1) {
    return;
  }

  const showTab = $panel => {
    if (!$panel.length || !$panel.attr('id')) return;

    // show new tab content
    $panel.removeClass('u-dn').siblings('.js-panel').addClass('u-dn');

    // track the tab change
    const $newTab = $tabs.filter(`[href="#${$panel.attr('id').replace('-tab', '')}"]`);
    const newTabName = $newTab.filter('.Tabs-tab').text().trim();
    const oldTabName = $tabs.filter('.Tabs-tab.is-active').text().trim();
    if (newTabName !== oldTabName) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'MainTabChange',
        from: oldTabName,
        to: newTabName
      });
    }

    // change tabs active state
    $newTab.addClass('is-active').siblings('.js-tab').removeClass('is-active');
    $(window).trigger('resize');
  };

  const hashChanged = () => {
    const anchor = document.location.hash.split('#')[1];
    if (anchor === undefined || anchor.lastIndexOf('configurator') === 0) {
      showTab($('#product-bundles-tab'));
    }
    else if (anchor !== undefined) {
      showTab($(`#${anchor}-tab`));
    }
    window.setTimeout(() => {
      if ($(window).scrollTop() >= $('.Tabs').eq(0).offset().top) {
        window.scrollTo(0, 0);
      }
    }, 16);
  };

  $panels.each((i, panel) => {
    panel.id = `${$(panel).attr('class').match(/js-panel-[\w-]*\b/)[0].replace('js-panel-', '')}-tab`;
  });
  $(window).on('hashchange', hashChanged);
  document.location.hash ? hashChanged() : showTab($panels.first());
})();
