import $ from 'jquery';

// http://chivil.com/uncomment/
// Author: Romuald Brunet <romuald@chivil.com>
//
// Example:
// <div class="slideshow">
//   <!--<img class="slide" src="http://dummyimage.com/900x400/ffed00/000.jpg&text=Slide+1">-->
//   <!--<img class="slide" src="http://dummyimage.com/900x400/dc0050/fff.jpg&text=Slide+2">-->
//   <!--<img class="slide" src="http://dummyimage.com/900x400/009ee0/fff.jpg&text=Slide+3">-->
// </div>
//
// To Uncomment this:
// $('.slideshow').uncomment();
//
// Note:
// $('element').uncomment(); will uncomment all comments directly inside the element
// $('element').uncomment(true); will uncomment all comments inside the element, by recursing the child nodes as well


(function uncommentClosure() {
  $.fn.uncomment = function uncomment(recurse) {
    $(this).contents().each(function each() {
      if (recurse && this.hasChildNodes()) {
        $(this).uncomment(recurse);
      }
      else if (this.nodeType === 8) {
        // Need to "evaluate" the HTML content,
        // otherwise simple text won't replace
        const e = $(`<span>${this.nodeValue}</span>`);
        $(this).replaceWith(e.contents());
      }
    });
  };
})();
