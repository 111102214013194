(() => {
  function listenForClicks(element) {
    element.addEventListener('click', e => {
      const el = e.currentTarget;
      const newClass = el.getAttribute('class').split('js-tglslf-')[1].split(' ')[0];
      el.classList.toggle(newClass);
    });
  }
  document.querySelectorAll('[class*="js-tglslf-"]').forEach(e => {
    listenForClicks(e);
  });
})();
