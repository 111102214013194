import $ from 'jquery';

// EXAMPLE
// <a class="Link Link--goTo js-anchor-scroll" href="#reporting-feautres" data-target=".Section--Example">All ASP.NET Core components</a>

(function initAnchorLinks() {
  const links = $('.js-anchor-scroll');

  if (!links.length) {
    return;
  }

  const onClick = function onClick(event) {
    const link = $(this);
    const elem = $(link.data('target'));

    if (!elem.length) {
      return;
    }

    $('html, body').animate({ scrollTop: elem.offset().top }, 400);

    event.preventDefault();
  };

  links.on('click.anchor-scroll', onClick);
})();
